<template>
  <div class="card bd-0 mg-b-20" style="background: transparent;">
      <div class="card-header bg-primary tx-white">
          <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
              <li v-for="t in tabs" class="nav-item">
                  <a class="nav-link text-white bd-0 pd-y-8 ft-left" v-bind:class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="selectTab(t.name)">{{t.name}}</a>
                  <a v-if="t.name !== 'Reminders'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-close" style="padding-left: 0; padding-right: 0; margin-left: -1rem;" v-bind:class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="closeTab(t.name)"><i class="fas fa-times"></i></a>
              </li>
          </ul>
      </div><!-- card-header -->
      <div class="card-body" style="background: transparent;">
          <claim v-for="tab in claimTabs" :ref="'claim-' + tab.id" v-show="tab.showing" :key="tab.id" :claim_id="parseInt(tab.id)" :client_id="parseInt(tab.client_id)" :presence="presence.users" @open-claim="selectClaim"></claim>
          <div v-show="selectedTab.name === 'Reminders'" class="row mg-t-20">
              <div class="col">
                <div class="card">
                  <div class="card-body">

                    <select v-if="$can('users-view-reminders')" :disabled="loading" v-model="processor" @change="loadReminders" class="form-control form-control-dark select2" v-select2>
                      <option v-for="user in processors" v-bind:value="user.id">{{user.email}}</option>
                    </select>

                    <br/>

                    <h1 v-if="numberChecked < numberOfClients">{{numberChecked}} of {{numberOfClients}} customers checked...</h1>

                    <table class="table">
                      <thead>
                        <th>#</th>
                        <th>REFERENCE</th>
                        <th>MESSAGE</th>
                        <th>CREATED AT</th>
                        <th>DUE ON</th>
                        <th v-if="$can('users-view-reminders')">REASSIGN</th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr v-if="reminders.length < 1">
                          <td colspan="6" class="text-center">No reminders found</td>
                        </tr>
                        <tr v-for="reminder in reminders" :key="'reminder-' + reminder.id">
                          <td>{{reminder.id}}</td>
                          <td style="cursor: pointer;" class="claim-link" @click="selectClaim(reminder)">{{reminder.reference}}</td>
                          <td>{{reminder.message}}</td>
                          <td>{{reminder.created_at}}</td>
                          <td>{{reminder.expires_at}}</td>
                          <td v-if="$can('users-view-reminders')">
                            <div class="input-group">
                              <select style="width: 70%;" :disabled="loading" v-model="selectedProcessor" class="form-control form-control-dark form-control-sm select2" v-select2>
                                <option v-for="user in processors" v-bind:value="user.id">{{user.email}}</option>
                              </select>
                              <div class="input-group-append">
                                <button class="btn btn-sm btn-primary" @click="reassignReminder(reminder)">Reassign</button>
                              </div>
                            </div>
                          </td>
                          <td><button class="btn btn-sm btn-danger" @click="deleteReminder(reminder)">Delete</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {DateTime} from 'luxon'
import Claim from "../claims/Claim";
import axios from 'axios';
import authHeader from '@/services/auth-header';
import Socket from '@/services/socket';

export default {
  name: 'Reminders',
  components: {
    Claim
  },
  data () {
    return {
      reminders: [],
      numberOfClients: 0,
      numberChecked: 0,
      tabs: [
          {
              name: "Reminders",
              claim: false,
              id: -1,
              showing: true
          }
      ],
      processors: [],
      processor: null,
      selectedProcessor: null,
      loading: true,
      socket: null,
      presence: {
          users: [],
          interval: null
      },
    }
  },
  created(){
        this.socket = new Socket(this.$store.state.auth.user.ws_url);
  },  
  unmounted(){
      this.socket.off('open-claim', (message) => {
          this.presence.users.push(message.content);
      });
      this.socket.off('close-claim', (message) => {
          this.presence.users = this.presence.users.filter(u => {
              return !(
                  u.reference == message.reference &&
                  u.user.id == message.content.user.id
              );
          });
      });
      clearInterval(this.presence.interval);
  },  
  async beforeRouteLeave(to, from, next){
        let claimTabs = this.tabs.filter(tab => {
            return tab.claim == true;
        });

        if(claimTabs.length > 0){
            console.log(`Found ${claimTabs.length} claim tabs`);
            for await (let tab of claimTabs){
                console.log(`Checking tab ${tab.id}`);
                let component = this.$refs[`claim-${tab.id}`][0];
                let canClose = await component.triggerClose();
                console.log(`Tab ${tab.id} can close: ${canClose}`);
                if(!canClose){
                    next(false);
                    return;
                }else{
                    if(tab.showing){
                        this.tabs.forEach(t => {
                            t.showing = t.name === 'Search';
                        });
                    }

                    this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
                        "reference": tab.name,
                        "user": {
                            "id": this.$store.state.auth.user.id,
                            "email": this.$store.state.auth.user.email
                        }
                    }});

                    this.tabs = this.tabs.filter(t => {
                        return t.id !== tab.id;
                    });
                }
            }
        }
        next();
    },
  methods: {
    loadReminders(){
      this.loading = true;
      this.numberChecked = 0;
      this.reminders = [];
      let clients = this.$store.state.auth.user.clients;
      this.numberOfClients = clients.length;
      clients.forEach((client) => this.locateReminders(client));
    },
    locateReminders(client){
      let url = `https://server.varsanpr.com/api/reminders/?client_id=${client.id}`;
      if(this.processor !== null){
        url += `&user_id=${this.processor}`;
      }
      axios.get(url, {
        headers: authHeader()
      })
      .then(response => {
        if(response.data){
          response.data.reminders.forEach((r) => {
            r.created_at = DateTime.fromSeconds(r.created_at).toFormat('dd LLL yyyy hh:mm:ss');
            r.expires_at = DateTime.fromSeconds(r.expires_at).toFormat('dd LLL yyyy hh:mm:ss');
            r.client_id = client.id;
          })
          this.reminders = this.reminders.concat(response.data.reminders);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.numberChecked++;
        if(this.numberChecked === this.numberOfClients){
          this.loading = false;
        }
      })
    },
    deleteReminder(reminder){
      axios.delete(`https://api.varsanpr.com/api/claims/${reminder.claim_id}/reminders/${reminder.id}`, {
        data: {
          client_id: reminder.client_id,
        },
        headers: authHeader()
      })
      .then(response => {
        this.reminders = this.reminders.filter(r => {
          let isSame = r.id === reminder.id && r.client_id === reminder.client_id;
          return !isSame;
        });
        this.$success("Reminder deleted successfully");
      })
      .catch(error => {
        this.$error("Failed to delete reminder", error);
      })
    },
    selectTab(t){
        let findTab = this.tabs.filter(tab => {
            return tab.name === t;
        })[0];

        if(!findTab) return;

        this.tabs.forEach(tab => {
            tab.showing = false;
        });

        findTab.showing = true;

    },
    async closeTab(t){
        let findTab = this.tabs.filter(tab => {
            return tab.name === t;
        })[0];

        if(!findTab) return;

        let component = this.$refs[`claim-${findTab.id}`][0];
            console.log(component);

            let canClose = await component.triggerClose();

            if(!canClose) return;

        if(findTab.showing){
            this.tabs.forEach(tab => {
                tab.showing = tab.name === 'Reminders';
            });
        }

        this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
                "reference": findTab.name,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});

        this.tabs = this.tabs.filter(tab => {
            return tab.id !== findTab.id;
        });
    },
    selectClaim(claim){
        // $router.push(`/claims/management/${claim.id}`);
        let existingCheck = this.tabs.filter(t => {
            return t.id == claim.claim_id;
        })[0];
        if(existingCheck){
            this.tabs.forEach(t => {
                t.showing = t.id === claim.claim_id;
            });
            return;
        }
        this.tabs.forEach(t => {
            t.showing = false;
        });
        this.tabs.push({
            name: claim.reference,
            id: claim.claim_id,
            client_id: claim.client_id,
            claim: true,
            showing: true
        });

        this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                "reference": claim.reference,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});
    },
    loadClaimProcessors(){
      if(!this.$can('claims-process')){
        return;
      }
      axios.get(`https://server.varsanpr.com/api/users/processors`, {
        headers: authHeader()
      })
      .then(response => {
        this.processors = response.data.users;
      })
      .catch(error => {
        this.$error("Failed loading processors!", error);
      });
    },
    reassignReminder(reminder){
      if(!this.$can('users-view-reminders')){
        return;
      }

      axios.patch(`https://api.varsanpr.com/api/claims/${reminder.claim_id}/reminders/${reminder.id}`, {
        client_id: reminder.client_id,
        new_user: this.selectedProcessor
      }, {
        headers: authHeader()
      })
      .then(response => {
        this.$success("Reminder reassigned successfully");
        this.loadReminders();
      })
      .catch(error => {
        this.$error("Failed to reassign reminder", error);
      });

    }
  },
  computed: {
      selectedTab: function(){
          return this.tabs.filter(t => {
              return t.showing;
          })[0];
      },
      claimTabs: function(){
          return this.tabs.filter(tab => {
              return tab.claim == true;
          });
      },
  },
  mounted () {
    this.socket.on('open-claim', (message) => {
            message._ts = Date.now();
            if(this.presence.users.filter(u => {
                return u.reference == message.reference &&
                    u.user.id == message.user.id
            }).length > 0){
                this.presence.users.filter(u => {
                    return u.reference == message.reference &&
                        u.user.id == message.user.id
                })[0]._ts = Date.now();
                return;
            }
            this.presence.users.push(message);
            this.claims.forEach((claim) => {
                if(claim.reference == message.reference){
                    if(claim.presence.filter((u) => {
                        return u.reference == message.reference &&
                            u.user.id == message.user.id
                    }).length < 1){
                        claim.presence.push(message);
                    }
                }
            });
        });
        this.socket.on('close-claim', (message) => {
            this.presence.users = this.presence.users.filter(u => {
                return !(
                    u.reference == message.reference &&
                    u.user.id == message.user.id
                );
            });
            this.claims.forEach((claim) => {
                if(claim.reference == message.reference){
                    claim.presence = claim.presence.filter((u) => {
                        return !(
                            u.reference == message.reference &&
                            u.user.id == message.user.id
                        );
                    });
                }
            });
        });
        this.presence.interval = setInterval(() => {
            this.tabs.forEach((tab) => {
                if(tab.name !== 'Search'){
                    this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                        "reference": tab.name,
                        "user": {
                            "id": this.$store.state.auth.user.id,
                            "email": this.$store.state.auth.user.email
                        }
                    }});
                }
            });

            this.presence.users = this.presence.users.filter((u) => {
                return Date.now() - u._ts < 15000;
            });
        }, 5000);
    this.loadClaimProcessors();
    this.loadReminders();
  }
}
</script>

<style scoped>
.card-header-tabs.nav-tabs-for-dark .nav-link.active {
    border-bottom: 2px solid white;
    background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: bold;
}

.tab-close:hover {
    color: red !important;
}

.claim-link:hover {
    color: dodgerblue;
    border-bottom: 1px solid dodgerblue;
}
</style>
